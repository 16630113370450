<template>
  <div
    v-if="display"
    class="mm-demanded-needs"
  >
    <div>
      <div class="mm-demanded-needs--section">
        <div>
          <h4> {{ StringsHelper.toPriceString(Number(needsCount.data.value) || 0, { unitDisplay: undefined }) }} </h4>
          <h4>{{ t(ERuLocales.Needs, Number(needsCount.data.value) || 0) }} <br> в плановые закупки на <br>текущий год</h4>
        </div>
      </div>
      <div class="mm-demanded-needs--section-right">
        <div>
          <div>
            <h4>Получите доступ к списку самых<br>востребованных товаров</h4>
            <p>Посмотрите какие позиции требуют закупки <br> в ближайшее время и получайте заказы первыми</p>
          </div>
          <NuxtLink
            v-if="linkDisplay"
            class="mm-link"
            :to="linkUrl"
            @click="onClickLink"
          >
            Перейти к списку товаров
            <SvgIcon
              :src="EIconPath.NavigationMainArrowSvg"
              class="mm-link__icon"
            />
          </NuxtLink>
        </div>
        <ClientOnly>
          <BaseImg
            src="/images/demanded-needs/demanded-needs-icon-224-2x.png"
            srcset="/images/demanded-needs/demanded-needs-icon-224.png 1x, /images/demanded-needs/demanded-needs-icon-224-2x.png 2x"
            class="demanded-needs-icon"
          />
        </ClientOnly>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { StringsHelper } from 'shared/utils/strings.util';
import SvgIcon from 'shared/components/SvgIcon.vue';
import BaseImg from 'shared/components/BaseImg.vue';
import { useUserStore } from 'store/user.store';
import { ERuLocales } from 'shared/enums/ruLocales.enum';
import { EIconPath } from 'shared/enums/iconPath.enum';
import { EFetchKey } from 'enums/fetchKey.enum';
import { useAuthFnHelper } from 'composables/useAuthHelper';
import { AuthManagerService } from 'services/auth/authManager.service';
import { UserHelper } from 'utils/userHelper.util';
import { NeedsApiService } from 'services/api/needsApi.service';

const userStore = useUserStore();
const { t } = useI18n();
const { showLoginModal } = useAuthFnHelper();

const needsCount = useLazyAsyncData<number| undefined>(
  EFetchKey.DemandedNeeds,
  async () => await NeedsApiService.getNeedsCount(),
);

const display = computed(
  () => (!needsCount.pending.value && typeof needsCount.data.value === 'number'),
);

const linkUrl = computed(
  () => userStore.isUserLoggedIn && UserHelper.isSupplier
    ? '/supplier/needs'
    : undefined,
);

const linkDisplay = computed(
  () => !userStore.isUserLoggedIn || UserHelper.isSupplier,
);

async function loginModalCallback(): Promise<void> {
  await nextTick(async () => await AuthManagerService.checkSupplierRedirect('needs'));
}

async function onClickLink(): Promise<void> {
  if (userStore.isUserLoggedIn && UserHelper.isSupplier) {
    return;
  }

  await showLoginModal(loginModalCallback, undefined, true, false);
}
</script>

<style lang="scss" scoped>
@import 'shared/assets/styles/base/common/variables';

.mm-demanded-needs {
  background: url('images/demanded-needs/demanded-needs-bg.svg');
  background-size: cover;
  height: 336px;
  margin-bottom: 60px;
  padding: 32px;
  position: relative;
  border-radius: 8px;

  > div {
    justify-content: space-between;
    align-items: flex-start;
    display: flex;

    .mm-demanded-needs--section {
      color: $text-white;

      h4 {
        font-size: 80px;
        line-height: 80px;
        font-weight: 500;
        margin-bottom: 0;
        height: fit-content;

        &:last-child {
          margin-top: 16px;
          font-size: 28px;
          line-height: 36px;
          font-weight: 500;
          margin-bottom: 0;
        }
      }

      button {
        margin-top: 48px;
        height: 20px;
        padding: 0;
      }

      &-right {
        width: 756px;
        border-radius: 8px;
        background-color: $text-white;
        height: 100%;
        display: flex;
        justify-content: space-between;
        padding: 24px;

        > div {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }

        h4 {
          font-size: 28px;
          color: $text-black;
          line-height: 36px;
          height: fit-content;
          font-weight: 500;
          margin-bottom: 16px;
        }

        p {
          margin-bottom: 0px;
          color: $text-light-green;
          font-size: 16px;
          line-height: 24px;
        }

        .btn-primary {
          height: 48px;
          width: 265px;
          gap: 4px;
          padding: 14px 24px;

          span {
            color: $text-white-6;
          }
        }

        .mm-link {
          &__icon {
            :deep(path) {
              fill: $link;
              transition: fill .2s;
            }
          }

          &:hover {
            :deep(path) {
              fill: $text-dark-green;
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1280px) {
  .mm-demanded-needs > div .mm-demanded-needs--section-right {
    padding-right: 40px;

    .demanded-needs-icon {
      width: 224px;
      height: 224px;
    }
  }
}

@media screen and (max-width: 1599px) {
  .mm-demanded-needs {
    height: 320px;
    padding: 24px;

    &--section {
      &-right {
        width: 706px;
      }
    }
  }
}

@media screen and (max-width: 1279px) {
  .mm-demanded-needs {
    margin-left: 4px;
    margin-right: 4px;
    background: url('images/demanded-needs/demanded-needs-bg-768.svg');
    height: 268px;
    padding: 16px;
    background-size: cover;

    > div {
      height: 100%;

      .mm-demanded-needs--section {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        button {
          margin-top: unset;
          margin-bottom: 16px;
        }

        h4 {
          font-size: 48px;
          line-height: 48px;
          margin-top: 16px;

          &:last-child {
            font-size: 16px;
            line-height: 24px;
          }
        }

        &-right {
          width: 483px;
          padding: 16px 24px;

          img {
            width: 160px;
            height: 160px;
          }

          h4 {
            font-size: 16px;
            line-height: 24px;
          }

          p {
            font-size: 14px;
            line-height: 20px;

            br {
              display: none;
            }
          }

          .demanded-needs-icon {
            width: 160px;
            height: 160px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .mm-demanded-needs {
    padding: 16px;
    height: fit-content;
    max-height: 628px;
    background: url('images/demanded-needs/demanded-needs-bg-360.svg');
    background-size: cover;

    > div {
      flex-direction: column;

      .mm-demanded-needs--section {
        height: unset;

        h4 {
          margin-top: 0;
          font-size: 40px;
          line-height: 44px;

          &:last-child {
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 16px;

            :deep(br) {
              display: none;
            }
          }
        }

        button {
          margin-bottom: 32px;
        }

        &-right {
          padding: 16px;
          width: 100%;
          flex-direction: column-reverse;
          align-items: center;
          justify-content: flex-end;
          gap: 32px;

          > div {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;
          }

          h4 {
            margin-bottom: 8px;
          }

          p {
            margin-right: 14px;
          }

          .btn-primary {
            width: 100%;
            margin-top: 32px;
          }
        }
      }
    }
  }
}
</style>
